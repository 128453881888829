import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/700.css";

import "./src/styles/global.scss";
export const onRouteUpdate = ({ location }) => scrollToAnchor(location);

function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash && typeof window !== "undefined") {
    const element = document.querySelector(
      `${location.hash}`
    ) as HTMLElement | null;
    if (!element) return false;
    const distance = element.offsetTop;

    // set data-scroll-margin="value" on the element to get the scroll margin top
    const scrollMargin = +(element.dataset.scrollMargin ?? 0);

    window.scrollTo({
      top: distance - mainNavHeight - scrollMargin,
      behavior: "smooth",
    });
  }

  return true;
}
